import * as React from "react"
import { Link, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Layout from "../components/layout"

const seoTitle = "ニュース｜ TANOSHIGE【たのしげ】"
const seoDescription = "皆さんに知っておいて欲しいお得な情報やTANOSHIGEの活動の様子などニュース記事としてお知らせ！"

// markup
const NewsPage = ({ data }) => {
  return (
    <Layout isHomePage={false} headerClass="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={seoTitle} description={seoDescription} />
      <main id="news-list">
        <h2><StaticImage src="../images/ttl-news@2x.png" alt="ニュース" /></h2>
        <section className="article-list inner">
          {data.allWpNews.edges.map(({ node }) => {
            const image = getImage(node.featuredImage.node.localFile)
            return (
              <article>
                <div className="article-thumbnail"><Link to={node.uri}><GatsbyImage image={image} alt={node.title} /></Link></div>
                <div className="article-ttl">
                  <p>{node.date}</p>
                  <h1><Link to={node.uri}>{node.title}</Link></h1>
                </div>
              </article>
            )
          })}
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          date(formatString: "YYYY. MM. DD")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NewsPage
